// src/pages/PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css'; // Import a CSS file for styling if needed

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h1>PRIVACY POLICY</h1>
            <p>Last updated March 25, 2025</p>
            <p>
                This privacy notice for Unofficial-software ("we," "us," or "our"),
                describes how and why we might collect, store, use, and/or share
                ("process") your information when you use our services ("Services"),
                such as when you:
            </p>
            <ul>
                <li>
                    Download and use our mobile application (Unofficial Guide-The
                    Villages), or any other application of ours that links to this
                    privacy notice
                </li>
                <li>Engage with us in other related ways, including any sales, marketing, or events</li>
            </ul>
            <p>
                Questions or concerns? Reading this privacy notice will help you
                understand your privacy rights and choices. If you do not agree with our
                policies and practices, please do not use our Services. If you still
                have any questions or concerns, please contact us at
                bswayne@icloud.com.
            </p>

            <h2>SUMMARY OF KEY POINTS</h2>
<p>
  This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.
</p>
<ul>
  <li>
    <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. This includes data collected through tools such as Google AdMob and Google Analytics.
  </li>
  <li>
    <strong>Do we process any sensitive personal information?</strong> We do not process sensitive personal information.
  </li>
  <li>
    <strong>Do we receive any information from third parties?</strong> We do not receive any information from third parties beyond analytics and advertising partners such as Google.
  </li>
  <li>
    <strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
  </li>
  <li>
    <strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties, including analytics and advertising partners like Google for service functionality.
  </li>
  <li>
    <strong>How do we keep your information safe?</strong> We have organizational and technical processes and procedures in place to protect your personal information. However, no system is 100% secure.
  </li>
  <li>
    <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.
  </li>
  <li>
    <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by visiting Found under profile → user accounts, or by contacting us at <a href="mailto:bswayne@icloud.com">bswayne@icloud.com</a>.
  </li>
</ul>

            <h2>TABLE OF CONTENTS</h2>
            <ol>
                <li>WHAT INFORMATION DO WE COLLECT?</li>
                <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
                <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
                <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
                <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
                <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
                <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
                <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
                <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                <li>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
                <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
                <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
                <li>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
            </ol>

            <div className="privacy-policy-container">
            <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>

<h3>Personal information you disclose to us</h3>

<p><strong>In Short:</strong> We collect personal information that you provide to us.</p>

<p>
  We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
</p>

<h4>Personal Information Provided by You</h4>

<p>
  The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
</p>

<ul>
  <li>Contact or authentication data</li>
</ul>

<h4>Sensitive Information</h4>

<p>We do not process sensitive information.</p>

<h4>Social Media Login Data</h4>

<p>
  We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.
</p>

<h4>Application Data</h4>

<p>
  If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
</p>

<ul>
  <li>
    <strong>Mobile Device Access:</strong> We may request access or permission to certain features from your mobile device, including your calendar, SMS messages, and other features. If you wish to change our access or permissions, you may do so in your device's settings.
  </li>
  <li>
    <strong>Analytics and Advertising Data:</strong> We use Google AdMob and Google Analytics to understand how our applications are used and to serve personalized or contextual ads. These tools may collect identifiers such as device ID, IP address, usage data, and interactions with ads.
  </li>
</ul>

<p>
  This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, analytics, advertising, and for our internal reporting purposes.
</p>

<p>
  All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
</p>
<h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>

<p><strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, to comply with law, and to personalize content and advertisements. We may also process your information for other purposes with your consent.</p>

<p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>

<ul>
  <li><strong>To facilitate account creation and authentication and otherwise manage user accounts:</strong> We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
  <li><strong>To request feedback:</strong> We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
  <li><strong>To improve and personalize your experience:</strong> We use analytics tools like Google Analytics to analyze usage patterns and improve our Services.</li>
  <li><strong>To serve advertising:</strong> We use tools such as Google AdMob to display relevant ads and understand ad performance within our applications.</li>
</ul>

<h2>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>

<p><strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties.</p>

<p>We may need to share your personal information in the following situations:</p>

<ul>
  <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
  <li><strong>Third-Party Service Providers:</strong> We may share your data with third-party vendors, service providers, contractors, or agents who perform services for us or on our behalf and require access to such information to do that work, including advertising and analytics partners such as Google.</li>
</ul>

<h2>4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>

<p><strong>In Short:</strong> If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</p>

<p>
  Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.
</p>

<p>
  We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.
</p>

<h2>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

<p><strong>In Short:</strong> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>

<p>
  We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.
</p>

<p>
  When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
</p>

<h2>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

<p><strong>In Short:</strong> We aim to protect your personal information through a system of organizational and technical security measures.</p>

<p>
  We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
</p>

<h2>7. DO WE COLLECT INFORMATION FROM MINORS?</h2>

<p><strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of age.</p>

<p>
  We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <a href="mailto:bswayne@icloud.com">bswayne@icloud.com</a>.
</p>

<h2>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>

<p><strong>In Short:</strong> You may review, change, or terminate your account at any time.</p>

<p><strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</p>

<p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>

<h4>Account Information</h4>

<p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>

<ul>
  <li>Log in to your account settings and update your user account.</li>
</ul>

<p>
  Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
</p>

<p>
  If you have questions or comments about your privacy rights, you may email us at <a href="mailto:bswayne@icloud.com">bswayne@icloud.com</a>.
</p>

                <h2>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

                <p>
                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
                </p>

                <h2>10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

                <p><strong>In Short:</strong> If you are a resident of the United States, you are granted specific rights regarding access to your personal information.</p>

                <h4>What categories of personal information do we collect?</h4>

                <p>We have collected the following categories of personal information in the past twelve (12) months:</p>
                <table>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Examples</th>
                            <th>Collected</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>A. Identifiers</td>
                            <td>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>B. Protected classification characteristics under state or federal law</td>
                            <td>Gender and date of birth</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>C. Commercial information</td>
                            <td>Transaction information, purchase history, financial details, and payment information</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>D. Biometric information</td>
                            <td>Fingerprints and voiceprints</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>E. Internet or other similar network activity</td>
                            <td>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>F. Geolocation data</td>
                            <td>Device location</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>G. Audio, electronic, visual, thermal, olfactory, or similar information</td>
                            <td>Images and audio, video or call recordings created in connection with our business activities</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>H. Professional or employment-related information</td>
                            <td>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>I. Education Information</td>
                            <td>Student records and directory information</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>J. Inferences drawn from collected personal information</td>
                            <td>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>K. Sensitive personal Information</td>
                            <td></td>
                            <td>NO</td>
                        </tr>
                    </tbody>
                </table>

                <p>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</p>

                <ul>
                    <li>Receiving help through our customer support channels;</li>
                    <li>Participation in customer surveys or contests; and</li>
                    <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                </ul>

                <h4>How do we use and share your personal information?</h4>

                <p>Learn about how we use your personal information in the section, "HOW DO WE PROCESS YOUR INFORMATION?"</p>

                <h4>Will your information be shared with anyone else?</h4>

                <p>
                    We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
                </p>

                <p>
                    We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.
                </p>

                <p>
                    We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
                </p>
                <h2>11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>

                <p><strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>

                <p>
                    We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                </p>

                <h2>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>

                <p>
                    If you have questions or comments about this notice, you may email us at <a href="mailto:bswayne@icloud.com">bswayne@icloud.com</a> or contact us by post at:
                </p>

                <p>
                    Unofficial-software<br />
                    __________<br />
                    __________
                </p>

                <h2>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>

                <p>
                    Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit: Found under profile --`{'>'}` user accounts.
                </p>

            </div>
        </div>
    );
};

export default PrivacyPolicy;